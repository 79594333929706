//TODO move to GRID component
@import "ag-grid-community/styles/ag-grid.css";
@import "ag-grid-community/styles/ag-theme-material.css";

@import "src/theme/_config";
@import "theme/_inputs";

@import "sass/_ag-grid-custom.scss";

@import url("https://fonts.googleapis.com/css2?family=Teko&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Hind:wght@400;500;600&display=swap");

* {
  outline: none;
}

body {
  margin: 0;
  padding: 0;
  background-color: var(--white);
  color: var(--black-20);
  font-size: 14px;
  font-weight: 400;
  line-height: 1.4;
  font-family: var(--secondary-font), serif;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  overflow: auto;
  scroll-behavior: smooth;
  overscroll-behavior: none;
}

//   scroll bar
::-webkit-scrollbar {
  height: 4px;
  width: 4px;
}

::-webkit-scrollbar-thumb {
  background-color: #334785;
  //outline: #333 solid 1px;
  border-radius: 5px;
}

::-webkit-scrollbar-corner {
  display: none;
}

::-webkit-scrollbar-track:hover {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.content-container {
  display: flex;
  flex-direction: column;
  margin: 0 65px 0 45px;
  gap: 10px;
}

.content-main-container {
  margin: 20px 0;
  padding: 0;
}

.ReactVirtualized__Grid__innerScrollContainer {
  & > div > div[class^=' css-'],
  & > div > div[class^='css-'] {
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
    padding: 5px 10px 5px 28px;
    background-color: white;
    color: #0E142C;

    &:hover, &:focus, &:active {
      cursor: pointer;
      color: inherit;
      background-color: #deebff;
    }
  }
}

.twiter-box {
  margin-left: 30px;
  border: 2px solid #c6ceda;
  border-radius: 16px;
  width: 400px;
  margin-top: 10px;

  .tweet-logo {
    padding-top: 20px;
    display: flex;
  }

  .tweet-icon {
    width: 100px;
    height: 50px;
  }

  .tweet-points {
    margin-top: 10px;
    margin-left: 20px;
  }
}

.tweetscores {
  font-weight: 600;
  font-size: 18px;
  margin-top: 12px;
  margin-left: 32px;
  color: #2d2da2;
}

.school-tweets {
  border: 2px solid #c6ceda;
  border-radius: 16px;
  margin-left: 30px;
  margin-top: 10px;
  width: 560px;
}

.twitter-page {
  .sub-heading {
    font-size: 15px;
    margin-top: 10px;
    margin-left: 30px;
    color: #8c8c8c;
  }

  .sub-sub-heading {
    font-size: 15px;
    margin-top: 10px;
    margin-left: 10px;
  }

  .heading {
    font-weight: 600;
    font-size: 20px;
    font-family: "SF-Pro-Text-Regular", sans-serif;
    border-bottom: 1px solid #c6ceda;
    padding: 20px;
  }

  .twitter-page-footer {
    margin-left: 30px;
    margin-top: 15px;
  }
}

.twitter-page1 {
  height: calc(100vh - 60px);
  background-color: white;
  overflow-y: auto;
  overflow-x: hidden;
}

.authenticated-details {
    margin-top: 10px;
    font-size: 17px;
    font-weight: 600;
}

.twitter-select-school {
  font-size: 21px;
  font-weight: 600;
  text-align: center;
  margin-top: 25px;
}

/*AUTOFILL RESETS*/
input:-webkit-autofill,
input:-webkit-autofill:focus,
input:-webkit-autofill:active,
textarea:-webkit-autofill,
select:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 30px #fafbfc inset !important;
  box-shadow: 0 0 0 30px #fafbfc inset !important;
  -webkit-transition: background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  transition: background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
}

button {
  &:disabled {
    opacity: 0.5;
    pointer-events: none;
  }
}
