.modal {
  width: 500px;
}

.confirmationModalButtons {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  gap: 15px;
}

.navbar {
  height: 112px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 22px;
  padding: 20px 65px 0;

  &::before {
    content: '';
    background: url('/src/assets/images/header/header-wave.png');
    background-size: 100% 100%;
    position: absolute;
    height: 112px;
    top: 0;
    left: 0;
    width: 100%;
    z-index: -1;
  }
}

.logo {
  max-width: 188px;
  height: 22px;
  width: 100%;
}

.actions {
  height: 100%;
  display: flex;
  flex-direction: row;
  gap: 15px;
  justify-content: center;
  align-items: center;
}

.userName {
  font-style: normal;
  font-size: 14px;
  color: var(--white);
  user-select: all;
  font-weight: 500;
  line-height: normal;
}

.invite {
  height: 35px;
  width: 135px;
  color: var(--pink-salmon);
  border: 1px solid var(--pink-salmon);
  background: transparent;
  font-weight: 500;
  font-size: 14px;
  line-height: normal;

  &:hover {
    background-color: var(--secondary-color);
    color: var(--white);
    box-shadow: 0 0 14px 2px rgba(202, 72, 31, 0.45);
  }
}

.orderDevice {
  height: 35px;
  min-width: 135px;
  color: var(--brand-gradient);
  background: var(--white);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.menuWrapper {
  position: relative;
}

.menu {
  background: var(--white);
  position: absolute;
  right: 0;
  margin: 10px 0;
  padding: 15px 20px;
  box-shadow: var(--non-focus-shadow);
  border-radius: 15px;
  z-index: 2;
  display: flex;
  flex-direction: column;
  width: max-content;

  & button {
    display: flex;
    gap: 10px;
    align-items: stretch;
    color: var(--primary-color);
  }

  &::after {
    content: '';
    position: absolute;
    right: 5px;
    top: -25px;
    border: 15px solid transparent;
    border-bottom: 15px solid #fff;
  }
}
